<template>
  <div :class="className" :style="{height:height,width:width}" />
</template>

<script>
import echarts from 'echarts'
require('echarts/theme/macarons') // echarts theme

export default {
  props: {
    className: {
      type: String,
      default: 'chart'
    },
    width: {
      type: String,
      default: '100%'
    },
    height: {
      type: String,
      default: '350px'
    },
    autoResize: {
      type: Boolean,
      default: true
    },
    chartData: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      chart: null
    }
  },
  watch: {
    chartData: {
      deep: true,
      handler(val) {
        this.setOptions(val)
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.initChart()
    })
  },
  beforeDestroy() {
    if (!this.chart) {
      return
    }
    this.chart.dispose()
    this.chart = null
  },
  methods: {
    initChart() {
      this.chart = echarts.init(this.$el, 'macarons')
      this.setOptions(this.chartData)
    },
    setOptions() {
      let xData = []
      let yData = []
      let dipmData = []
      for (let item of this.chartData) {
        xData.push(item.time)
        yData.push(+item.order_count)
        dipmData.push(+item.dipm_fee)
      }
      this.chart.setOption({
        xAxis: {
          data: xData,
          boundaryGap: false,
          axisTick: {
            show: false
          }
        },
        title: {
          text: '近15天新申请单统计',
          left: '2%',
        },
        grid: {
          right: '2%',
          bottom: '3%',
          left: '2%',
          containLabel: true
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross'
          },
          padding: [5, 10]
        },
        yAxis: [
          {
            type: 'value',
            name: '费用',
            axisLabel: {
              formatter: '{value} SEK'
            }
          },
          {
            type: 'value',
            name: '数量'
          }
        ],
        legend: {
          data: ['申请单', '使馆费']
        },
        series: [
          {
            name: '申请单',
            smooth: true,
            type: 'bar',
            data: yData,
            yAxisIndex: 1,
          },
          {
            name: '使馆费',
            smooth: true,
            type: 'line',
            data: dipmData,
            animationDuration: 2800,
            animationEasing: 'cubicInOut'
          }
        ]
      })
    }
  }
}
</script>
