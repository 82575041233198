<template>
  <div class="index-container">
    <div class="index-boxs">
      <div class="index-box-outer" v-for="(item, index) in dataList" :key="item.key">
      <div class="index-box">
        <div class="item-hd">
          <svg-icon :icon-class="item.icon" />
          <span class="item-title">{{item.title}}</span>
          <el-tooltip :content="item.tip">
            <svg-icon icon-class="help" class="cursor c-gray" />
          </el-tooltip>
        </div>
        <h2 class="item-number">
          <count-to
            :endVal="+totalData[item.key].value || 0"
            :duration="2000"
          />
        </h2>
        <div class="item-bd" v-if="false">
          <svg-icon icon-class="datepicker" />
          <span class="item-date">{{today}}</span>
          <el-tooltip class="item" effect="dark" content="统计结果为前一天数据" placement="right">
            <svg-icon icon-class="tip" class="f-12 cursor item-tip" />
          </el-tooltip>
        </div>
        <div class="item-ft">
          <span class="item-cell">
            <span>月同比</span>
            <svg-icon :icon-class="totalData[item.key].month_rate > 0 ? 'up' : 'down'" :class="totalData[item.key].month_rate > 0 ? 'c-green' : 'c-red'"></svg-icon>
            <span :class="totalData[item.key].month_rate > 0 ? 'c-green' : 'c-red'">{{+totalData[item.key].month_rate}}%</span>
          </span>
          <span class="item-cell">
            <span>周环比</span>
            <svg-icon :icon-class="totalData[item.key].week_rate > 0 ? 'up' : 'down'" :class="totalData[item.key].week_rate > 0 ? 'c-green' : 'c-red'"></svg-icon>
            <span :class="totalData[item.key].week_rate > 0 ? 'c-green' : 'c-red'">{{+totalData[item.key].week_rate}}%</span>
          </span>
        </div>
        </div>
      </div>
    </div>

    <!-- 今日数据 -->
    <div class="index-today" v-if="isAdmin">
      <h2>今日数据</h2>
      <span v-if="isAdmin">总用户数：<a>{{today.customer_all}}</a></span>
      <span v-if="isAdmin">新增用户数：<a>{{today.customer_today}}</a></span>
      <span>新增申请单：<a>{{today.order_count}}</a></span>
    </div>
    <div class="index-chart" v-if="isAdmin">
      <BarChart :chart-data="customerTable" />
    </div>
    <div class="index-chart">
      <LineChart :chart-data="orderTable" />
    </div>
  </div>
</template>

<script>
import index from '@/api/index'
import { parseTime } from '@/utils/index'
import CountTo from 'vue-count-to'
import { mapGetters, mapState } from 'vuex'
import dayjs from 'dayjs'

import BarChart from './components/BarChart'
import LineChart from './components/LineChart'
export default {
  components: {
    CountTo,
    BarChart,
    LineChart,
  },
  data() {
    return {
      icons: ['fans', 'dianz', 'comment', 'video'],
      activeTab: 'daily',

      totalData: {
        cancel_count: {},
        collect_count: {},
        dipm_fee: {},
        delivery_fee: {},
        order_count: {},
      },

      today: {},
      orderTable: [],
      customerTable: [],
    }
  },
  computed: {
    ...mapGetters(['isAdmin']),
    ...mapState(['user']),
    dataList() {
      if (this.isAdmin) {
        return [
          { key: 'delivery_fee', icon: 'money', title: '快递费总额', tip: '所有的快递单费用总额' },
          { key: 'dipm_fee', icon: 'index', title: '使馆费总额', tip: '所有的快递单费用总额' },
          { key: 'order_count', icon: 'apply_order', title: '申请单数量', tip: '系统中的快递单数量' },
          { key: 'collect_count', icon: 'data', title: '待揽件数量', tip: '待揽件的快递单数量' },
        ]
      }
      // 使馆
      if (this.user.diplomaticDTO.type == 0) {
        return [
          { key: 'dipm_fee', icon: 'index', title: '使馆费总额', tip: '所有的快递单费用总额' },
          { key: 'order_count', icon: 'apply_order', title: '申请单数量', tip: '系统中的申请单数量' },
          { key: 'collect_count', icon: 'data', title: '待揽件数量', tip: '待揽件的申请单数量' },
          { key: 'cancel_count', icon: 'close', title: '取消数量', tip: '已取消的申请单数量' },
        ]
      }
      // 其他情况
      return [
        { key: 'delivery_fee', icon: 'money', title: '快递费总额', tip: '所有的快递单费用总额' },
        { key: 'order_count', icon: 'apply_order', title: '春节包数量', tip: '系统中的快递单数量' },
        { key: 'collect_count', icon: 'data', title: '待揽件数量', tip: '待揽件的快递单数量' },
        { key: 'cancel_count', icon: 'close', title: '取消数量', tip: '已取消的快递单数量' },
      ]
    }
  },
  created() {
    this.fetchData()

    this.isAdmin && index.today()
    .then(res => {
      this.today = res || {}
    })

    index.orderTable()
    .then(res => {
      this.orderTable = this.fixDate(res || [], 'dipm_count')
    })

    this.isAdmin && index.customerTable()
    .then(res => {
      this.customerTable = this.fixDate(res || [], 'user_count')
    })
  },
  methods: {
    fetchData() {
      const time = parseTime(new Date, '{y}-{m}-{d} {h}:{i}:{s}')
      index.statisticsData({ time })
      .then(res => {
        this.totalData = { ...res }
      })
    },
    tabChange(tab) {
      this.activeTab = tab
    },
    fixDate(data, valueKey = 'value') {
      let map = {}
      for (let item of data) {
        map[item.time] = item
      }

      var date = new Date()
      let ret = []
      for (let i = 0; i < 15; i++) {
        const day = dayjs().subtract(i, 'day').format('YYYY-MM-DD')
        if (!map[day]) {
          ret.push({
            time: day,
            [valueKey]: 0
          })
          continue
        }
        ret.push(map[day])
      }

      return ret.sort((a, b) => dayjs(a.time) - dayjs(b.time))
    }
  }
}
</script>

<style lang="less" scoped>
.index {
  &-container {
  }
  &-boxs {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    overflow: hidden;
    line-height: 20px;
    color: rgba(0, 0, 0, .65);
    font-size: 14px;
    margin-right: -24px;
    .svg-icon {
      font-size: 16px;
    }
  }
  &-box-outer {
    padding-right: 24px;
    width: 25%;
    flex: none;
  }
  &-box {
    border-radius: 4px;
    background: #fff;
    padding: 24px;
    white-space: nowrap;
    .item-hd {
      display: flex;
      align-items: center;
    }
    .item-title {
      flex: 1;
      margin-left: 6px;
    }
    .item-number {
      margin: 16px 0;
      line-height: 40px;
      font-size: 32px;
      color: #000;
    }
    .item-bd {
      .svg-icon {
        font-size: 14px;
        margin-right: 4px;
      }
    }
    .item-date {
      margin-right: 6px;
    }
    .item-tip {
      color: #D4D7D9;
    }
    .item-ft {
      margin-top: 16px;
      display: flex;
      justify-content: space-between;
      .svg-icon {
        font-size: 18px;
        margin: 0 4px;
      }
    }
    .item-cell {
      flex: 1;
      line-height: 20px;
      display: flex;
      align-items: center;
    }
  }
  &-today {
    padding: 20px;
    background: #fff;
    h2 {
      margin: 0;
      font-size: 18px;
      border-left: 4px solid #40c9c6;
      padding-left: 10px;
      margin-bottom: 20px;
    }
    span {
      margin-right: 20px;
      color: rgba(0, 0, 0, .65);
      font-size: 14px;
      a {
        color: #40c9c6;
        font-weight: bold;
      }
    }
  }
  &-chart {
    padding: 20px;
    margin-top: 24px;
    background: #fff;
  }

}
@media (max-width: 768px) {
  .index-box-outer {
    width: 100%;
    margin-bottom: 24px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .index-boxs {
    flex-wrap: wrap;
  }
}
</style>
