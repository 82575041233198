import request, { get, post } from '@/utils/request'

export default {
  statisticsData(params = {}) {
    return get('/rest/statistics/order', params)
  },

  today() {
    return get('/rest/dashboard/today')
  },

  orderTable() {
    return get('/rest/statistics/order/dashboard')
  },

  customerTable() {
    return get(`/rest/statistics/customer/dashboard`)
  }
}
